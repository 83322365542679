export const rrg = `
At the start of October, I got to spend some great time climbing and exploring in Kentucky's Red River Gorge. It was my first time in the area and the climbing didn't disappoint. The true highlight of the trip though was the friends. Becca was able to join since she flew out to Wisconsin with me after celebrating our friend Cam's wedding the week before, and we joined with several other friends from Madison and Chicago to rent an airbnb for the weekend.
<br/><br/>
We left on a Thursday, and got back the next Monday. It was a 9 hour drive both ways, so the first and last day were all driving. Despite getting stuck in construction traffic through Chicago, our spirits were high as we were looking forward to the weekend ahead. Our plan was to stop for groceries on our drive down, which became a twenty minute dash through the grocery store in Lexington as we rolled into town shortly before all the stores closed. It was drizzling when we pulled into our home for the weekend at ten at night, but warm, in the mid 60s. We were hopeful that we could find some dry rock the following day, but knew that we'd have a great back-up plan going hiking or caving.
<br/><br/>
We had a slow start the next morning, but after each drinking a few cups of coffee and loading up on scrambled eggs, we headed out to explore. The ground was still wet and the trees were still dripping, so we decided to go on a hike in the morning and see if we could find some climbing in the afternoon. It felt great to stretch our legs, and we got some great views from the top of the Natural Bridge, a feature in Kentucky's Natural Bridge State Park.
<br/><br/>
<img src="/photos/blog/naturalBridge.jpg" alt="Photo from the top of the Natural Bridge in the Red River George, Kentucky" />
<br/><br/>
After finishing up our hike, we set off to look for some rocks to climb. We decided to go to Miur Valley, which my friends described as the best maintained outdoor crag in the world. From the climbing areas I've been to, I'd have to agree. The trails were well maintained, the routes had tags with their names and grades, and the grassy area at the bottom of the valley was freshly mowed. The area was crowded, but we found a small wall with four or five moderate routes and climbed them all as warmups.
<br/><br/>
We wanted to make it to the Rocktoberfest climbing festival going on that evening in a different part of the area, but thought we could squeeze a few more climbs in. We decided to head to the Solarium wall, a spectacular overhanging cliff that must have been 50 meters tall and boasted hard sport climbs. I gave my best attempt at the route Air Ride Equipped, which with it's 5.11a rating would have been my hardest route on lead. I fought my way up it, but at the last bolt ran out of strength and hung to rest. I was still pretty happy with my performance, and was excited for the next few days of climbing.
<br/><br/>
<img src="/photos/blog/airRide.jpg" alt="Max climbing the route Air Ride Equipped" />
<br/><br/>
We ran into trouble on our way home. It was dark by the time we started hiking out of Solarium, and we took a wrong turn on a gravel road. By the time we got to the state highway, we were three miles from where we parked. Luckily, after ten or fifteen minutes of walking, a fellow climber gave us a ride back to the cars to save us the rest of the walk. We ended up missing the movie at the climbing festival, but still made it in time to check out the vendor booths and chat with other climbers. We all tried the "crimp the barrel" challenge, which involved two people lifting a heavy wine barrel with just their finger tips. I made it to 40 seconds, but two of my friends, Andrew and Joey, made it to two minutes and ten seconds, winning the competition and earning a free climbing rope.
<br/><br/>
The next two days were filled with more exciting climbing and fun moments with friends. By the end of the weekend our forearms were smoked, our legs were tired, and we were satisfied. A pancake breakfast at Miguel's gave us fuel for the long ride home. Many podcasts later and we arrived safely back in Madison, already looking forward to going back to the Gorge next year.

<img src="/photos/blog/peopleClimbing.jpg" alt="Max climbing the route Air Ride Equipped" />

`;